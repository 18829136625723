import userServices from './userServices';
import liveRatesServices from './liveRatesServices';
import liveFeedServices from './liveFeedServices';

// var servicesUsed = {
//   userServices,
//   liveRatesServices,
//   liveFeedServices
// };

export { userServices, liveRatesServices, liveFeedServices };

// if (typeof window !== 'undefined') {
//   window.services = servicesUsed;
// }
