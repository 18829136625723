import ajax from '../utils/ajax';
import url from '../constants/serverUrl';

export default {
  getLiveRatesSuggestions(query) {
    return ajax({
      url: `${url.serverURL}/api/v0.1/autocomplete`,
      query: { query: query }
    });
  },
  getLiveSpotRates() {
    return ajax({
      url: `${url.serverURL}/api/v0.1/realTimePriceIndices`
    });
  },
  getLanesHistory(laneHistoryObj) {
    let ourDate = new Date();
    let pastDate = ourDate.getDate() - 180;
    ourDate.setDate(pastDate);
    let startTimestamp = ourDate.getTime();
    return ajax({
      url: `${url.serverURL}/api/v0.1/lanes/history`,
      query: {
        startDate: startTimestamp,
        endDate: Date.now(),
        laneId: laneHistoryObj.laneId,
        vehicleLengthId: laneHistoryObj.vehicleLengthId,
        vehicleTypeId: laneHistoryObj.vehicleTypeId,
        vehicleWeightId: laneHistoryObj.vehicleWeightId
      }
    });
  },
  getTopLanes(topLanesArray) {
    let at = '';

    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('sessionToken')) {
        at = window.localStorage.getItem('sessionToken');
      }
    }
    let actualHeaders;
    if (at !== '') {
      actualHeaders = {
        'Content-Type': 'application/json',
        token: at
      };
    } else {
      actualHeaders = {
        'Content-Type': 'application/json'
      };
    }
    return ajax({
      url: `${url.serverURL}/api/v0.1/lanes`,
      headers: actualHeaders,
      method: 'POST',
      body: JSON.stringify({
        laneDetails: topLanesArray
      })
    });
  }
};
