import ajax from '../utils/ajax';
import url from '../constants/serverUrl';

export default {
  getLiveFeed(offset, fromLocation, toLocation) {
    let fromPlaceId, toPlaceId;
    let query = {};
    if (offset) {
      query.offset = offset;
    } else {
      query.offset = 0;
    }
    if (fromLocation) {
      fromPlaceId = fromLocation.placeId;
      query.startGooglePlaceId = fromPlaceId;
    }
    if (toLocation) {
      toPlaceId = toLocation.placeId;
      query.endGooglePlaceId = toPlaceId;
    }
    let at = '';

    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('sessionToken')) {
        at = window.localStorage.getItem('sessionToken');
      }
    }
    return ajax({
      url: `${url.serverURL}/api/v0.1/feed`,
      query: query,
      headers: {
        token: at
      }
    });
  }
};
