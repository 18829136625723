//TODO - add this - https://babeljs.io/docs/en/babel-plugin-transform-object-assign for Object.assign

/**
 *
 * @param {Object} obj object
 * @param {String} obj.url url to send request
 * @param {Object} obj.body body for post method
 * @param {Object} query queries for url
 */

function ajax(obj) {
  let url;
  try {
    url = new URL(obj.url);
    if (obj.jsonData === true) {
      obj.body = JSON.stringify(obj.body);
    }
  } catch (err) {
    console.error('err', err);
    return;
  }

  const { query } = obj;

  try {
    if (query) {
      for (let key in query) {
        if (query.hasOwnProperty(key)) {
          url.searchParams.append(key, query[key]);
        }
      }
    }
  } catch (err) {
    console.error(err);
  }

  obj.headers = Object.assign(
    {
      Accept: 'application/json',
      'Accept-Encoding': 'application/gzip',
      'Access-Control-Allow-Origin': '*',
      v: 2,
      s: 'WEB'
    },
    obj.headers
  );

  return fetch(url.href, obj).then(function(response) {
    if (response.status === 204 || response.status === 401) {
      //clear local storage
    } else {
      return response.json();
    }
  });
}

export default ajax;
