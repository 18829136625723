/**
 *
 * @param {String} query query to search the places that begin with this e.x del: delhi
 */
import ReactGA from 'react-ga';
import url from '../constants/serverUrl';
import ajax from '../utils/ajax';

export default {
  authenticateUser() {
    let accessToken = null;
    // let formData = new FormData();

    if (typeof localStorage !== 'undefined') {
      try {
        accessToken = localStorage.getItem('sessionToken');
      } catch (ex) {
        console.error(ex);
      }
    }
    if (accessToken === null) {
      let response = { code: 1 };
      return response;
    }
    // formData.append('token', accessToken);

    return ajax({
      url: `${url.baseLoginURL}/oauth/check_token?token=${accessToken}`,
      // body: formData,
      method: 'POST'
    });
  },

  sendOtp(phoneNumber) {
    let formData = new FormData();
    formData.append('emailMobileCode', phoneNumber);
    formData.append('type', 'LOGIN');
    return ajax({
      url: `${url.baseLoginURL}/sendotp`,
      body: formData,
      method: 'POST'
    });
  },

  verifyOtp(phoneNumber, otp, name, email, userTypeSelected) {
    let formData = new FormData();
    // formData.append('phoneNumber', phoneNumber);
    // formData.append('otp', otp);
    // if (userTypeSelected) {
    //   formData.append('permissions', userTypeSelected);
    // }
    formData.append('mobile', phoneNumber);
    formData.append('otp', otp);
    formData.append('client_id', 'freight');
    formData.append('ou', 'freight-users');
    formData.append('first_name', name);
    formData.append('last_name', '-');
    formData.append('email', email);
    return ajax({
      url: `${url.baseLoginURL}/verifyOtpAndRegisterUserAndGetAccessToken`,
      body: formData,
      method: 'POST'
    });
  },

  sendEmailAlerts(email) {
    return ajax({
      url: `${url.vyomCrmURL}/api/subscribe?email=${email}`,
      method: 'POST'
    });
  },
  getLoginConfig() {
    return ajax({
      url: `${url.vyomCrmURL}/api/survey`
    });
  },
  getMappings() {
    // return ajax({
    //   url: `${url.serverURL}/api/v0.1/config`
    //
    return {
      lengthBindings: {
        10: '6 tyre(19-24 ft)',
        20: '10 tyre',
        30: '12 tyre',
        40: '14 tyre',
        50: '18 tyre',
        60: '22 tyre',
        70: 'LCV (14-17 ft)',
        80: 'Tempo 407',
        90: '19-22 ft sxl',
        100: '24 ft sxl',
        110: '24 ft mxl',
        120: '32 ft sxl',
        130: '32 ft sxl HQ',
        140: '32 ft mxl',
        150: '32 ft mxl HQ',
        160: 'High bed',
        170: 'Semi bed',
        180: 'Low bed',
        190: '32 ft txl',
        200: '32 ft txl HQ'
      },
      weightBindings: {
        10: '1 ton',
        20: '1.5 ton',
        30: '2 ton',
        40: '2.5 ton',
        50: '3 ton',
        60: '3.5 ton',
        70: '4 ton',
        80: '4.5 ton',
        90: '5 ton',
        100: '5.5 ton',
        110: '6 ton',
        120: '6.5 ton',
        130: '7 ton',
        140: '7.5 ton',
        150: '8 ton',
        160: '9 ton',
        170: '14 ton',
        180: '14.5 ton',
        190: '15 ton',
        200: '16 ton',
        210: '20 ton',
        220: '21 ton',
        230: '22 ton',
        240: '23 ton',
        250: '24 ton',
        260: '25 ton',
        270: '26 ton',
        280: '27 ton',
        290: '28 ton',
        300: '33 ton',
        310: '34 ton',
        320: '35 ton',
        330: '20.5 ton'
      },
      typeBindings: {
        1: 'Open',
        2: 'Trailer',
        3: 'Container'
      }
    };
  },
  revokeAuthToken() {
    let accessToken = '';
    if (typeof window !== 'undefined') {
      accessToken = window.localStorage.getItem('sessionToken');
    }
    return ajax({
      url: `${url.baseLoginURL}/oauth/revoke/${accessToken}`,
      method: 'DELETE'
    });
  },
  sendAnalyticsEvents(analyticsData) {
    let formattedLabel = analyticsData.label;
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('userMobile')) {
        formattedLabel += `, Phone Number: ${window.localStorage.getItem('userMobile')}`;
      }
    }
    ReactGA.event({
      category: analyticsData.category,
      action: analyticsData.action,
      label: formattedLabel
    });
    return;
  },
  sendContactUsEmail() {
    return ajax({
      url: `${url.serverURL}/api/v0.1/contactUsMail`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        s: 'SUPPLY'
      },
      body: {
        fromAddress: 'shreynik.kumar@rivigo.com',
        toAddress: 'shreynik.kumar@rivigo.com',
        subject: 'Test',
        messageBody: 'Test email body for a user',
        mimeType: 'text/html'
      },
      jsonData: true
    });
  },
  // API to get the list of all the cities
  getAllNFIHubs() {
    return ajax({
      url: `${url.vyomCrmURL}/api/hub/nfi`
    });
  },
  // API to get the list of prices for all the cities
  getAllNFIHubPrices() {
    return ajax({
      url: `${url.vyomCrmURL}/api/pricing`
    });
  },
  // API to get autocomplete suggestions for the city
  getSuggestions(city) {
    return ajax({
      url: `${url.vyomCrmURL}/api/address/autocomplete?query=${city}`
    });
  },
  getLatLongForPlaceID(placeID) {
    return ajax({
      url: `${url.vyomCrmURL}/api/address/add?placeId=${placeID}`,
      method: 'POST'
    });
  },
  getPriceForSelectedOptions(originCity, destinationCity) {
    let body = {
      origin: {
        latitude: originCity.latitude,
        longitude: originCity.longitude
      },
      destination: {
        latitude: destinationCity.latitude,
        longitude: destinationCity.longitude
      },
      businessType: 'INDUSTRIAL',
      vehicleType: 'CONTAINER',
      vehicleWeight: 'SEVENTEEN_POINT_FIVE_TON',
      vehicleLength: 'THIRTY_TWO_FEET_MXL'
    };
    let rawBody = JSON.stringify(body);
    return ajax({
      url: `${url.vyomCrmURL}/api/nfi/price`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json'
      },
      body: rawBody
    });
  },
  postPriceForSelectedOptions(refNo, price) {
    let body = {
      price: price,
      priceReferenceNo: refNo
    };
    return ajax({
      url: `${url.vyomCrmURL}/api/user/price`,
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
        credentials: 'include',
        accept: 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('sessionToken')}`
      },
      body: JSON.stringify(body)
    });
  },
  // Get Avg Fuel Rate For Graph
  getAvgFuelRate() {
    return ajax({
      url: `${url.vyomCrmURL}/api/freight-index/AVG_FUEL_RATE`
    });
  },
  getFreightIndex() {
    return ajax({
      url: `${url.vyomCrmURL}/api/freight-index/FREIGHT_INDEX`
    });
  },
  submitSurveyConfig(userType, phoneNo) {
    let body = {
      userRoleType: userType,
      phoneNumber: phoneNo
    };
    let data = JSON.stringify(body);
    return ajax({
      url: `${url.vyomCrmURL}/api/survey`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        accept: 'application/json'
      },
      body: data
    });
  }
};
