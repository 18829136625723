module.exports = [{
      plugin: require('/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://85eeac1254b044fe9fa27c7630fa650f@sentry.io/1447312","environment":"production","enabled":true},
    },{
      plugin: require('/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6V9ZT3GN4S"],"pluginConfig":{"head":true}},
    },{
      plugin: require('/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
