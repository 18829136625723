/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import '@bit/rivigo.ui.reset-css';
import 'whatwg-fetch';
import './src/services';
import './src/css/global.css';
import './src/ln/LangConfig';

require('typeface-lato');
