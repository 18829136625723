// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-generic-blog-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/templates/GenericBlog.js" /* webpackChunkName: "component---src-templates-generic-blog-js" */),
  "component---src-pages-404-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-contact-index-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faqs-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-index-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/Privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/Terms_of_use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-white-paper-nfi-rivigo-js": () => import("/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/src/pages/white_paper_NFI_rivigo.js" /* webpackChunkName: "component---src-pages-white-paper-nfi-rivigo-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/ec2-user/.jenkins/jobs/National_freight_index_Production_Deployments/workspace/.cache/data.json")

