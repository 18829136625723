import langEngine from 'lang-engine';
import loopobject from 'loopobject';
import allLangData from './allLangData';

var langArr = ['hi', 'zh'];
var defaultFallBackLang = 'en';
var defaultLang = 'en';

if (typeof window !== 'undefined') {
  if (!window.localStorage.getItem('langKey')) {
    window.localStorage.setItem('langKey', 'en');
  } else {
    defaultLang = window.localStorage.getItem('langKey');
  }
}

//TODO - change langauge from command line.

loopobject(allLangData, (moduleLangData, moduleName) => {
  loopobject(moduleLangData, (langData, key) => {
    allLangData[`${moduleName}.${key}`] = langData;
  });
});

loopobject(allLangData, (langData) => {
  langArr.forEach((lang) => {
    if (langData[lang] === undefined) {
      langData[lang] = langData[defaultFallBackLang];
    }
  });
});
langEngine.setLanguage(defaultLang);
langEngine.add(allLangData);
