var serverURL = 'INVALID_GATSBY_API_URL';
var vyomCrmURL = process.env.GATSBY_VYOM_CRM_URL;
var baseLoginURL = process.env.GATSBY_RIVIGO_SSO_URL;
try {
  if (process !== undefined && process.env !== undefined && process.env.GATSBY_API_URL !== undefined) {
    serverURL = process.env.GATSBY_API_URL;
  } else {
    throw new Error();
  }
} catch (error) {
  if (typeof window !== 'undefined') {
    alert('Please check, I guess .env file is missing, because I am unable to read env variable.');
  }
  throw new Error('Please check, I guess .env file is missing, because I am unable to read env variable.');
}
try {
  //Overweite for changes in Server URL via LocalStorage.
  if (typeof window !== 'undefined') {
    try {
      if (localStorage.getItem('uiConfig')) {
        let x = JSON.parse(localStorage.getItem('uiConfig')).serverURL;
        if (x !== undefined) {
          serverURL = x;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
} catch (ex) {
  console.error(ex);
}

export default { serverURL, vyomCrmURL, baseLoginURL };
